import React from "react"
import Layout from "../../layouts/Layout"
import Helmet from "react-helmet"
import { Sucess } from "../../components/Sucess"

const ContactPageSuccess = () => (
  <Layout>
    <Helmet>
      <title>Mensaje enviado exitosamente</title>
      <meta name="robots" content="noindex" />
      <meta name="googlebot" content="noindex" />
    </Helmet>
    <Sucess
      to="/es/"
      title="Su mensaje fue enviado exitosamente."
      content="Me pondré en contacto con usted tan pronto como sea posible."
    />
  </Layout>
)

export default ContactPageSuccess
