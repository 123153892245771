import React from "react"
import {
  StyledBox,
  StyledContainer,
  StyledImage,
  StyledSubTitle,
  StyledTitle,
} from "./Style"
const ImageSuccess = "/images/contact_success.svg"

const Sucess = ({ to, title, content }) => (
  <StyledContainer to={to}>
    <StyledImage src={ImageSuccess} alt="Not Found" />
    <StyledBox>
      <StyledTitle>{title}</StyledTitle>
      <StyledSubTitle>{content}</StyledSubTitle>
    </StyledBox>
  </StyledContainer>
)

export default Sucess
